// const BASE_URL = "https://myshelta.com/shelterbackend/public/api/";
const BASE_URL = "https://myshelta.com/Testbackend/public/api/";

import store from '../store';

// bodyType = "json" or "FormData"

export default function client({ URL_PATH, method, body, bodyType = "json" }) {
  return new Promise((resolve, reject) => {
    let options = {
      method: method,
      mode: "cors"
    };

    // let token = null;

    // let user = JSON.parse(localStorage.getItem('smartUser'))
    // let token = JSON.parse(localStorage.getItem('smartToken'))
    
    if (bodyType !== "FormData") {
      options.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
        Authorization: `Bearer ${store.getters.getUser.token}`,
        shelta: "web"
      };
      // console.log("userrrrr", localStorage.getItem('smartUser').token);
    } else {
      options.headers = {
        // Authorization: `Bearer ${token}`,
        Authorization: `Bearer ${store.getters.getUser.token}`,
        shelta: "web"
      };
      // console.log("userrrrr", localStorage.getItem('smartUser').token);
    }

    if (body) {
      options.body = body;
    }

    fetch(`${BASE_URL}${URL_PATH}`, options)
     //.then(response => response.json())
      .then(response => {
        // console.log("Responseeeeeee", response)
        if (response.status == 401 && BASE_URL == "https://myshelta.com/shelterbackend/public/api/" && window.location.href !== 'https://smart.myshelta.com/') {
          localStorage.clear();
          window.location.href = 'https://smart.myshelta.com/'; // Redirect to login page
          return;
        } 
        if (response.status == 401 && BASE_URL == 'https://myshelta.com/Testbackend/public/api/' && window.location.hostname !== "localhost" && window.location.href !== 'https://smarttest.myshelta.com/') {
          localStorage.clear();
          window.location.href = 'https://smarttest.myshelta.com/'; // Redirect to login page
          return;
        }
        if (response.status == 401 && window.location.hostname === "localhost" && window.location.href !== `http://localhost:${window.location.port}/`) {
          localStorage.clear();
          // localStorage.removeItem("shelterUser");
          // localStorage.removeItem("smartToken");
          // localStorage.removeItem("smartUser"); 
          window.location.href = '/'; // Redirect to login page
          return;
        } 
        else {
          return response.json();
        }
      })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
    });
  });
}
